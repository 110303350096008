<template>
  <RolesIndex
    :create-text="'New Role'"
    :can-create="
      !!$store.getters['entities/user-permissions/find']('create roles')
    "
    :can-delete="
      !!$store.getters['entities/user-permissions/find']('delete roles')
    "
    :can-edit="!!$store.getters['entities/user-permissions/find']('edit roles')"
  ></RolesIndex>
</template>
<script>
import RolesIndex from "@/components/Roles/RolesIndex";

export default {
  name: "Roles.index",
  components: { RolesIndex },
};
</script>