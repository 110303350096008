<template>
  <div>
    <CampusHeader
      :can-create="canCreate"
      :activeTab="0"
      @start-creating="startCreatingRole"
      :manage-campuses="true"
      :header-text="headerText"
      :create-text="createText"
      :header_tabs="[{ title: `${tabEntity} List` }]"
    ></CampusHeader>
    <section class="section pt-4 box">
      <div class="">
        <div class="columns">
          <div class="column">
            <b-field grouped>

              <b-field expanded label="Filter" horizontal>
                <!--suppress HtmlUnknownAttribute -->

                <b-input
                  v-debounce:300ms="getFilteredRoles"
                  placeholder="Search"
                  :loading="loadingFilter"
                  v-model="searchHold"
                ></b-input>
              </b-field>
            </b-field>
          </div>
        </div>
        <b-table
          class="margin-top is-clickable"
          :data="roles"
          :per-page="limit"
          :paginated="true"
          :loading="loadingData"
          :striped="true"
          :hoverable="true"
          :bordered="false"
          @click="view"
        >
          <template #empty>
            <div class="has-text-centered">No Roles</div>
          </template>
          <b-table-column
            v-slot="props"
            label="ID"
            field="id"
            sortable
            width="40"
            numeric
            >{{ props.row.id }}
          </b-table-column>

          <b-table-column v-slot="props" sortable field="name" label="Name"
            >{{ props.row.name }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              sortable
              field="permissions.length"
              centered
              narrowed
              label="Permissions"
          >
              {{ props.row.permissions.length }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label=""
              custom-key="actions"
              centered

          >
            <div v-on:click.stop>
              <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
                <template #trigger>
                  <b-icon
                      :icon="$tc('icons.more')"/>
                </template>
                <b-dropdown-item @click="startDelete(props.row)"
                                v-if="canDelete"
                                aria-role="listitem">Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-table-column>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
import Role from "@/models/Role";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import Campus from "@/models/Campus";
import RoleForm from "@/components/Roles/RoleForm";
import Permission from "@/models/Permission";

export default {
  name: "Roles.index.component",
  components: { CampusHeader },
  data() {
    return {
      search: "",
      loadingFilter: false,
      roleDataHold: null,
      meta: Object,
      page: 1,
      limit: 20,
      loadingData: false,
      searchHold: null,
    };
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Roles";
      },
    },
    createText: {
      type: String,
      default() {
        return "Roles";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Roles";
      },
    },
    type: {
      type: String,
      default() {
        return "Role";
      },
    },
  },
  methods: {
    startDelete(role) {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.type}`,
        confirmText: `Delete ${this.type}`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this ${this.type}?`,
        onConfirm: () =>
          Role.Delete(role.id)
            .then(() => {
              this.$buefy.snackbar.open(`${this.type} deleted!`);
            })
            .catch((err) => {
              this.handleError(err)
            }),
      });
    },
    startCreatingRole() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          canEdit: this.canCreate

        },
        component: RoleForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },

    view(role) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          roleObject: role,
          canEdit: this.canEdit,
        },
        component: RoleForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    getFilteredRoles(text) {
      this.search = text;
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    campuses() {
      return Campus.query().get();
    },
    roles() {
      return Role.query()
        .with("permissions")
        .where((role) => {
          return role.name.includes(this.search);
        })
        .get();
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");

    Promise.all([
      Role.FetchAll({ page: 1, limit: 999 }, ["permissions"]),
      Permission.FetchAll(),
    ]).then(() => {
      this.$store.dispatch("loader/hide");
    });
  },
};
</script>
