<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ edit === false ? "Create a new" : "Edit" }} Role
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="Name">
          <b-input v-model="role.name" placeholder="Role name" required>
          </b-input>
        </b-field>
        <b-field label="Permissions Filter">
          <b-input v-model="permissionFilter" placeholder="Permission">
          </b-input>
        </b-field>
        <b-field label="Permissions">
          <b-table
            class="margin-top"
            :data="permissions"
            :loading="loadingData"
            :striped="true"
            :hoverable="true"
            :bordered="false"
          >
            <template #empty>
              <div class="has-text-centered">No Permissions</div>
            </template>
            <b-table-column
              v-slot="props"
              label="ID"
              field="id"
              sortable
              width="40"
              numeric
              >{{ props.row.id }}
            </b-table-column>

            <b-table-column v-slot="props" sortable field="name" label="Name"
              >{{ props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              width="80"
              centered
              label="Actions"
              cell-class="py-1"
              custom-key="actions"
            >
              <b-field grouped>
                <b-field>
                  <b-checkbox
                    v-model="role.permissions"
                    :native-value="props.row.id"
                  ></b-checkbox>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button
          v-if="canEdit"
          class="button"
          type="is-primary"
          :icon-right="$tc(`icons.${edit === false ? 'create' : 'edit'}`)"
        >
          Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import Role from "@/models/Role";
import Permission from "@/models/Permission";
import PermissionRole from "@/models/PermissionRole";

export default {
  name: "RoleForm",
  data() {
    return {
      loadingData: false,
      permissionFilter: "",
      role: {
        name: this.name,
        permissions: [],
      },
    };
  },
  methods: {
    submit() {
      if (this.edit) {
        if (this.canEdit) {
          PermissionRole.delete((role) => {
            return role.role_id === this.role.id
          })
          Role.Update(this.role, true)
              .then(() => {
                this.$buefy.snackbar.open(`Role updated!`);
                this.$emit("close");
              })
              .catch((err) => {
                this.handleError(err)
              });
        } else {
          this.$store.dispatch("toast/createToast");
        }
      } else {
        Role.Store(this.role)
          .then(() => {
            this.$buefy.snackbar.open(`Role created!`);
            this.$emit("close");
          })
          .catch((err) => {
            this.handleError(err)
          });
      }
    },
  },
  watch: {
    roleObject(newValue) {
      if (newValue !== null && this.edit) {
        this.role = this.roleObject;
        this.role.permissions = this.roleObject.permissions.map(
          (permission) => {
            return permission.id;
          }
        );
      }
    },
  },
  computed: {
    permissions() {
      return Permission.query()
        .where((permission) => {
          return permission.name.includes(this.permissionFilter);
        })
        .get();
    },
  },
  mounted() {
    this.loadingData = true;
    Permission.FetchAll({ page: 1, limit: 999 }).then(() => {
      this.loadingData = false;
    });
    if (this.edit) {
      this.role = this.roleObject;
      this.role.permissions = this.roleObject.permissions.map((permission) => {
        return permission.id;
      });
    }
  },
  props: {
    roleObject: {
      type: Object,
      default() {
        return null;
      },
    },
    edit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

